import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import SplitSection from "../components/SplitSection"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"

const Title = styled.h2`
  font-family: "Minion Pro";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16pt;
  line-height: 19.6pt;
  letter-spacing: 0.1em;
  width: 360px;
  max-width: 100%;
  text-align: center;
  margin-bottom: 30px;
  white-space: pre-line;
`

const Description = styled.p`
  font-family: "Minion Pro";
  width: 360px;
  text-align: justify;
  max-width: 100%;
  line-height: 17.2pt;
  font-size: 10.6pt;
`

type Props = {
  data: {
    file: IGatsbyImageData
  }
}

const About = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("about")} />
      <SplitSection image={data.file} imagePosition="right">
        <Title>{t("about_title")}</Title>
        <Description>{t("about_description")}</Description>
      </SplitSection>
    </>
  )
}

export default About

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "about-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
